.tag.display {
  display: flex;
  overflow-x: scroll;
  padding: 6px;
  justify-content: flex-start;
  max-width: 1000px;
  width: calc(100% - 24px);
  margin: 0 auto;
  margin-bottom: 10px;
  .item {
    font-family: "Questrial", sans-serif;
    font-size: 18px;
    transition: 500ms ease;
    padding: 7px 14px;
    border-radius: 5px;
    white-space: nowrap;
    margin: 0 4px;
    background: var(--background-main);
    color: var(--text);
  }
  .item:hover {
    box-shadow: var(--node-card-shadow);
    cursor: pointer;
  }
}

.tag.display::-webkit-scrollbar {
  height: 4px;
  // width: 10px;
}

/* Track */
.tag.display::-webkit-scrollbar-track {
  background: var(--bg-main-cards);
}

/* Handle */
.tag.display::-webkit-scrollbar-thumb {
  background: var(--btn-regular-bg);
}

/* Handle on hover */
.tag.display::-webkit-scrollbar-thumb:hover {
  background: var(--btn-regular-bg-hov);
}
