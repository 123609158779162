.loader { 
    width: 90%;
    margin:0 auto;
    border-radius:10px;
    border:4px solid transparent;
    position:relative;
    padding:1px;
  }
  .loader:before {
    content:'';
    border: 1px solid transparent;
    border-radius:10px;
    position:absolute;
    top:-4px; 
    right:-4px; 
    bottom:-4px; 
    left:-4px;
  }
  .loader .loader-bar { 
    position:absolute;
    border-radius:10px;
    top:0;
    right:100%;
    bottom:0;
    left:0;
    background:var(--text); 
    width:0;
    animation:borealisBar 2s linear infinite;
  }
  
  @keyframes borealisBar {
    0% {
      left:0%;
      right:100%;
      width:0%;
    }
    10% {
      left:0%;
      right:75%;
      width:25%;
    }
    90% {
      right:0%;
      left:75%;
      width:25%;
    }
    100% {
      left:100%;
      right:0%;
      width:0%;
    }
  }