.cnf-applet {
  .full {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 999;
  }
  .float-cnt {
    position: relative;
    width: 100%;
    max-width: 1007px;
    margin: 0 auto;
  }
  .float {
    position: absolute;
    top: 54px;
    right: 3px;
    max-width: 300px;
    width: 100%;
    background: var(--background-main);
    box-shadow: var(--node-card-shadow);
    border-radius: 5px;
    padding: 3px;
    z-index: 99;
    & > div {
      display: flex;
      justify-content: space-between;
      padding: 7px 4px;
      align-items: center;
      & > button {
        width: 100%;
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .cnf-applet {
    .float {
      right: unset;
      left: 4%;
      max-width: unset;
      width: 90%;
      & > div {
        padding: 10px 8px;
      }
    }
  }
}
