/* Clases de uso comun en varias paginas o componentes */

@use "../styles/mixins/positions" as p;
@use "../styles/_colors.scss" as c;
@use "./paddings.scss" as pd;

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;700&display=swap");
/* Position starts */
.relative {
  position: relative;
}

.absolute-0 {
  @include p.absolute(0 0 0 0);
}

.sticky {
  position: sticky;
}

.abs-t-0-r-0 {
  @include p.absolute(top 0 right 0);
}

.abs-y-r-half {
  @include p.absolute(top 7.5% right 12.5%);
}

/* Position ends */
/* Width sizes starts */
.width-cnt {
  width: max-content;
}

.width-25 {
  width: 25%;
}
.width-50 {
  width: 50%;
}
.width-two-thirds {
  width: 66.666667%;
}
.width-75 {
  width: 75%;
}
.width-94 {
  width: 94%;
}
.width-95 {
  width: 95%;
}
.width-96 {
  width: 96%;
}
.width-99 {
  width: 99%;
}

.w-6r {
  width: 6rem;
}
.h-6r {
  height: 6rem;
}

.w-full {
  width: 100%;
}

.w-fit {
  width: fit-content;
}

/* Width sizes ends */
/* Height sizes starts */

.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}

/* Height sizes ends */
/* Margin sizes starts */
.mzeroauto {
  margin: 0 auto;
}

.mt-half {
  margin-top: 0.5rem;
}
.mt-1r {
  margin-top: 1rem;
}
.mt-2r {
  margin-top: 2rem;
}
.mt-3r {
  margin-top: 3rem;
}

.mt-25p {
  margin-top: 25%;
}
.mt-15p {
  margin-top: 5%;
}
.ml-half {
  margin-left: 0.5rem;
}
.ml-12p {
  margin-left: 12.5%;
}
.ml-15p {
  margin-left: 15%;
}
.ml-7-half {
  margin-left: 7.5%;
}

.mr-half {
  margin-right: 0.5rem;
}
.mr-1r {
  margin-right: 1rem;
}
.mr-3r {
  margin-right: 3rem;
}

.mb-half {
  margin-bottom: 0.5rem;
}
.mb-1r {
  margin-bottom: 1rem;
}
.mb-3r {
  margin-bottom: 2rem;
}
/* Margin sizes ends */
/* Padding sizes starts */

.p-3r {
  padding: 3rem;
}

.pr-3r {
  padding-right: 3rem;
}
.p-1 {
  padding: 1rem;
}
.pr-1 {
  padding-right: 1rem;
}
.pl-half {
  padding-left: 0.5rem;
}
.px-half {
  padding-right: 2rem;
  padding-left: 2rem;
}

.p-0d5-half {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.py-1r {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pt-3-half {
  padding-top: 3.5rem;
}

.pt-2-half {
  padding-top: 2.5rem;
}
/* Padding sizes ends */
/* shadows starts */
.shadow-topbar {
  box-shadow: var(--shadow-topbar);
}
.shadow-1 {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
}
.shadow-2 {
  box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
    0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 8px 10px -7px rgba(0, 0, 0, 0.2);
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.shadow-3 {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
/* overflow starts */
.overflow-h {
  overflow: hidden !important;
}
/* overflow ends */
/* text size starts */

.text-s {
  font-size: 0.85rem;
}
.text-m {
  font-size: 1rem;
}
.text-mm {
  font-size: 1.25rem;
}
.text-l {
  font-size: 1.5rem;
}
.text-xl {
  font-size: 1.8rem;
}

/* text size ends */
/* text align starts */
.text-right {
  text-align: right;
}
/* text align ends */
/* text decoration starts */
.underline {
  text-decoration: underline;
}

/* text decoration ends */
/* flexbox starts */
.flex {
  display: flex;

  &.justify-between {
    justify-content: space-between;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-arround {
    justify-content: space-around;
  }
  &.justify-evenly {
    justify-content: space-evenly;
  }
  &.items-center {
    align-items: center;
  }
  &.align-center {
    align-items: center;
  }

  &.flex-col {
    flex-direction: column;
  }
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/* flexbox ends */
/* displays starts */
.block {
  display: block !important;
}
/* displays ends */
/* border radius starts */
.rounded {
  border-radius: 0.25rem;
}
.rounded-sm {
  border-radius: 5px;
}
.rounded-md {
  border-radius: 10px;
}
.rounded-lg {
  border-radius: 20px;
}
.rounded-xl {
  border-radius: 30px;
}
/* border radius ends */
/* text styles starts*/
.font-bold {
  font-weight: bold;
}

.text-lg {
  font-size: 1.125rem;
}
/* text styles ends*/
.bg-indigo-light {
  background-color: #ebf4ff !important;
}

.bg-main {
  background: var(--background-main);
}

.shine {
  background: #3b3b44;
  background-image: linear-gradient(
    to right,
    #3b3b44 0%,
    #35353d 20%,
    #3b3b44 40%,
    #3b3b44 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}
.shine-theme {
  background: var(--shimmer-1);
  background-image: linear-gradient(
    to right,
    var(--shimmer-1) 0%,
    var(--shimmer-2) 20%,
    var(--shimmer-1) 40%,
    var(--shimmer-1) 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

box {
  height: 100%;
  width: 100%;
}

box.shine.img-viewer {
  height: 75vh;
  width: 50vw;
}

box.shine-theme.gallery {
  width: 196px;
  height: 151px;
  position: absolute;
}

.filter-none {
  filter: none !important;
}

.tablero-edit-ac {
  background: var(--board-accent) !important;
}

.text-theme {
  color: var(--text) !important;
}

.text-dark {
  color: #53535f !important;
}

.mobile_toolbar_top_spacer {
  top: 46px;
}

@media screen and (max-width: 600px) {
  .p-node-editor {
    padding: 8px !important;
  }
  .mbl-block {
    display: block !important;
  }
  .mbl-100pct {
    width: 100% !important;
  }
  .mbl-p-unst {
    padding: unset !important;
  }
  .mbl-txt-a-lft {
    text-align: left !important;
  }
}

@media screen and (min-width: 601px) {
  .p-node-editor {
    padding: 8px 0 !important;
  }
  .mobile_toolbar_top_spacer {
    top: unset;
  }
}

.font-plex-sans {
  font-family: "IBM Plex Sans", sans-serif;
}

.font-questrial {
  font-family: "Questrial", sans-serif;
}

.modal-bg {
  background-color: var(--background-main) !important;
  color: var(--text);
}
.tox .tox-tbtn svg {
  filter: none;
}
.bg-blue-5 {
  background-color: var(--blue-5) !important;
}
.overlay-transparent {
  background: rgba(250, 250, 250, 0);
}

.alert-help {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: break-spaces;
  padding: 0.5rem 1.5rem;
}

.brd-top-sep {
  border-top: 1px solid var(--border-item);
  padding: 0.5rem 5px;
}

.gap-2 {
  gap: 0.5rem; /* 8px */
}
