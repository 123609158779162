.home {
  width: 100%;
  background: var(--bg-main-cards);
  color: var(--text);
  min-height: 100vh;
  .bar {
    width: 100%;
    text-align: center;
    padding: 9px 0;
    display: flex;
    justify-content: space-between;
    max-width: 1007px;
    margin: 0 auto;
    align-items: center;
    .logo {
      width: 46px;
      min-height: 37px;
      margin: 0 9px;
    }
    input {
      background: var(--background-main);
      font-family: "Questrial", sans-serif;
      font-size: 18px;
      border-radius: 8px;
      padding: 7px 20px;
      border: 1px solid var(--bg-main-cards);
      color: var(--text);
      width: 100%;
    }
  }
  .cnt {
    width: 100%;
    background: var(--bg-main-cards);
    max-width: 1007px;
    margin: 0 auto;
    padding: 15px 0;
    border-bottom: 1px solid var(--background-main);
    .title {
      padding: 3px 0 7px 12px;
      font-size: 18px;
      position: relative;
    }
    .infinite-scroll-component {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 32px;
      height: auto;
      overflow: unset !important;
    }
    .item {
      width: 313px;
      height: 383px;
      background: var(--background-main);
      border-radius: 32px;
      position: relative;
      font-family: "Questrial", sans-serif;
      font-size: 18px;
      box-shadow: var(--node-card-shadow);
      transition: 500ms ease;
      .bookmark-cnt {
        position: absolute;
        width: 20px;
        right: 14px;
        top: 14px;
        z-index: 99;
        box-shadow: 0px 0px 21px 3px var(--background-main);
        height: 21px;
        background: var(--background-main);
        border-radius: 4px;
        button {
          width: 100%;
        }
      }
      .cover {
        width: 100%;
        height: 162px;
        overflow: hidden;
        border-top-left-radius: 32px;
        border-top-right-radius: 32px;
        border-bottom: 1px solid var(--bg-main-cards);
        position: absolute;
        top: 0;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .context {
        text-align: center;
        padding: 13px 24px;
        position: absolute;
        width: calc(100% - 48px);
        top: 162px;
        h2 {
          font-size: 24px;
          font-weight: 400;
          height: 50px;
        }
        p {
        }
      }
      .toolbar {
        position: absolute;
        padding: 0 21px;
        bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 271px;
        .expanded {
          width: 100%;
        }
        .partial {
          width: calc(100% - 39px);
        }
        button {
          background: var(--btn-regular-bg);
        }
        .main {
          padding: 10px 13px;
        }
        .float {
          button {
            padding: 5px 0;
          }
        }
      }
    }
    .item:hover {
      box-shadow: var(--node-card-shadow-hover);
      cursor: pointer;
    }
    .item.active {
      box-shadow: 0 1px 3px 0 var(--pagination-hover-active-color),
        0 1px 2px 0 rgb(0 0 0 / 6%);
    }
    .list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 32px;
    }
  }
}

// phone fix
@media screen and (max-width: 680px) {
  .home {
    .cnt {
      .title {
        padding: 3px 0 7px 12px;
      }
      .infinite-scroll-component {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 32px;
      }
      .item {
        margin: 0 auto;
      }
      .list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 32px;
      }
    }
  }
}

// mid fix
@media screen and (min-width: 680px) and (max-width: 1030px) {
  .home {
    .cnt {
      .title {
        padding: 3px 0 7px 12px;
      }
      .infinite-scroll-component {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
      }
      .item {
        margin: 0 auto;
      }
      .list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
      }
    }
  }
}
