:root{
    --danger:#ff4747;
    --float-shadow:#d3d3d9;
    --blue1: #00adb5;
    --colrgba:#323630;
    --blue-5:#4299E1;
}
/* fuentes */
@import url('https://fonts.googleapis.com/css?family=Questrial');

* {
    margin: 0;
    padding: 0;
}

.disable_scroll {
    max-height: 100vh!important;
    overflow: hidden!important;
}

.App{
    /*color:var(--text);*/
}
svg{
    filter: var(--color-icon)
}
.Error404 {
    text-align: center;
    font-family: 'Questrial', sans-serif;
}

.Main {
    width: 100%;
    background-color: var(--col1);
    color:var(--text);
}
.app{
    color:var(--text);
    position: absolute;
}
.TopBar {
    width: 100%;
    height: 46px;
    position: fixed;
    z-index: 1;
    background-color: var(--background-main);
    box-shadow: 0px 5px 6px -7px var(--shadow-accent);
    color:var(--text);
}

.Editor {
    width: 100%;
    position: fixed;
    height: calc(100% - 46px);
    background-color: var(--background-board);
    margin: 46px 0 0;
    z-index: 0;
}


.NavigationBar {
    width: 100%;
    height: 60px;
    background-color: var(--col1);
    position: fixed;
    bottom: -1px;
}

/* Common */
.WsBttn {
    width: 40px;
    height: 40px;
    margin: 3px;
    display: inline-flex;
}

.i-search {
    background-color: unset;
    font-family: 'Questrial', sans-serif;
    border-width: 0;
    width: 100%;
    font-size: 17px;
    border-radius: 8px;
    padding-left: 20px;
    color: var(--text);
}

.quad-40-icon {
    width: 40px;
    height: 40px;
    margin: 3px;
    display: inline-flex;
}

.nav-wrapper {
    width: fit-content;
    margin: 0 auto;
}

.NavBttn {
    width: 40px;
    height: 40px;
    margin: 2px 13px;
    display: inline-flex;
    border-radius: 5px;
    color: var(--col4);
    background-color: var(--col2);
}

.NavLabel {
    position: absolute;
    width: 44px;
    margin: 43px -2px 0px;
    text-align: center;
    font-size: 12px;
    font-family: 'Questrial', sans-serif;
}

.NavBttn:hover {
    background-color: var(--col3);
}

.nav-active .nav-icon {
    background-color: var(--col3);
    border-radius: 5px;
}

.nav-active span {
    font-size: 14px;
    color: var(--col3)
}

.nodes-edit {
    width: 100%;
    height: 100%;
}

.node.previewcnt {
    position: relative;
    margin: 0 auto;
    margin-top: calc(-100vh + 16vh);
    width: 285px;
}

a.node.link {
    text-decoration: none;
    width: 260px;
}

div.node.preview {
    padding: 11px;
    border-radius: 5px;
    background: var(--col2);
    width: 260px;
    margin: 0 auto;
    box-shadow: 0px 1px 13px -2px var(--col3);
    position: relative;
}

div.node.prev {
    color: var(--col4);
}

button, input[type="submit"] , input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

h2.node.title {
    font-size: 18px;
    font-family: 'Questrial', sans-serif;
    padding-left: 38px;
}

h2.node.content {
    font-size: 14px;
    font-family: 'Questrial', sans-serif;
}

p.node.textcomplete {
    display: none;
}

span.node.pin {
    display: none;
}

.search_page.container {
    font-family: 'Questrial', sans-serif;
    font-size: 17px;

    /*padding-top: 46px;*/
    position: absolute;
    margin: 0 auto;
    /*border-bottom: var(--col3) 1px solid;*/
    width: 100%;
    max-width: 577px;
    background: var(--background-main);
}

.search_page.container.node_editor {
    padding-top: unset;
}

.node.editor.child.search_page.container {
    /*padding-top: unset;
    margin-top: 50px;
    background: unset;*/
    max-width: 100%;
    height:100%;
}
.quick.create.cnt.node_editor {
    background: var(--colrgba);
    overflow: auto;
    width: 100vw !important;

}
.search_page.container.node_editor{
    width:100%;
    max-width: 100%;
}

p.search_page.item {
    padding: 6px 0 5px 5px;
    border-top: var(--border-item) 1px solid;
    /*background-color: var(--item-accent);*/
    color: var(--col4);
    cursor: pointer;
}

p.search_page.item.title {
    padding: 2px 12px 2px 0;
    font-size: 12px;
    text-align: right;
    background-color: var(--background-main);
    cursor: unset;
}
.search_page.item.accent-bg{
    background-color: var(--item-accent);
    border-top: var(--background-main) 2px solid !important;
}

.search_page.edit {
    right: 6px;
    position: absolute;
    border: var(--col1) 1px dashed;
    height: 24px;
    margin-top: -8px;
    padding: 2px 2px;
    border-radius: 6px;
}

.search_page.edit.active {
    border: var(--col3) 1px dashed;
}

.search_page.edit svg {
    height: 24px;
}

.search_page.edit:hover {
    border: var(--col4) 1px dashed;
}

.deep.container {
    font-family: 'Questrial', sans-serif;
    padding: 6px 0 5px 0px;
    font-size: 17px;
    position: relative;
    margin: 0 auto;
    border-bottom: var(--col3) 1px solid;
    width: 100%;
    max-width: 577px;
    background-color: var(--col1);
    color: var(--col4);
}

span.deep.item {
    margin-left: 18px;
    background-color: var(--col4);
    color: var(--col1);
    padding: 1px 13px;
    border-radius: 4px;
}

span.deep.label {
    padding: 0 0 0 5px;
}

span.deep.item.selected {
    background-color: var(--col3);
    color: var(--col1);

}

.app {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: var(--float-shadow);
}

.app.float {
    overflow-y: auto;
    background-color: unset;
    cursor: pointer;
}

.appfloatcnt {
    width: 100%;
    cursor: default
}

span.new-node.save {
    position: absolute;
    right: 2px;
}

span.new-node.select-type {
    position: absolute;
    margin: 7px 8px;
}

.fllscr {
    position: absolute;
    top: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

select {
    font-size: 19px;
    font-family: 'Questrial', sans-serif;
    color: #53535f;
    line-height: 1.3;
    padding: 5px 0 4px 4px;
    width: auto;
    border: 1px solid var(--text);
    border-radius: 5px;
    background-color: #F7F7F8;
    -moz-appearance: none;
}

.node.input.wrapper {
    width: 100%;
    background-color: var(--col2);
    margin-top: 4px;
}

.node.input svg {
    width: 100%;
}

.new-node.editor {
    position: absolute;
    margin-top: 46px;
    padding: 5px;
    background-color: var(--col1);
}

.node.input.img.label {
    text-align: center;
    color: var(--col4);
    font-size: 17px;
    font-family: 'Questrial', sans-serif;
    margin-top: -18px;
}

input.node.input.std::placeholder {
    color: var(--col4);
    opacity: 0.7;
}

input.node.input.std {
    background-color: var(--col2);
    border-style: none;
    color: var(--col4);
    font-family: 'Questrial', sans-serif;
    font-size: 22px;
    padding: 4px 0px;
    width: 100%;
}

.node.input.wrapper.html {
    background-color: white;
    min-height: 400px;
}

.login-page {
    display: grid;
    grid-template-columns: auto 300px auto;
    height: 100vh;
    margin: 0;
    overflow: hidden;
    font-family: 'Gudea', sans-serif;
    background: #EA5C54;
    background: -moz-linear-gradient(-45deg, var(--col3), var(--col2));
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #EA5C54), color-stop(100%, #bb6dec));
    background: -webkit-linear-gradient(-45deg, var(--col3), var(--col2));
    background: -o-linear-gradient(-45deg, var(--col3), var(--col2));
    background: -ms-linear-gradient(-45deg, var(--col3), var(--col2));
    background: linear-gradient(135deg, var(--col3), var(--col2));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EA5C54 ', endColorstr='#bb6dec', GradientType=1);
}

.login-page div {
    grid-column: 2/3;
    display: inline-grid;
    background-color: var(--col1);
    padding-top: 120px;
}

.central-form {
    display: inline-grid;
    height: 100px;
    padding: 11px 4px;
    border-radius: 6px;
}

.central-form input {
    height: 2em;
    margin: 3px 0;
    border-radius: 8px;
    padding: 0 7px;
    font-family: 'Questrial', sans-serif;
    background-color: var(--col2);
    color: var(--col4);
    border-style: unset;
}

.central-form input.sub {
    background-color: var(--col3);
}

.central-form span {
    margin: 7px 13px 0 19px;
    position: absolute;
    color: var(--col3);
    font-family: 'Questrial', sans-serif;
}

.singIn {
    height: 2em;
    margin: 3px 0;
    border-radius: 8px;
    padding: 0 7px;
    font-family: 'Questrial', sans-serif;
    background-color: var(--col2);
    color: var(--col3);
    border-style: unset;
    text-decoration: none;
    width: 100%;
}

.top_bar_flat.item {
    padding: 11px 0 8px 5px;
    border-top: var(--border-item) 1px solid;
    background-color: var(--current-board);
    position: relative;
}

.top_bar_flat.item.active {
    background-color: var(--search-bar);
    cursor: pointer;
}

.top_bar_flat.item.active.new-board {
    background-color: var(--new-board);
}

.top_bar_flat.item.cat {
    border: var(--col4) 1px dashed;
    border-radius: 5px;
    color: var(--col4);
    position: absolute;
    right: 5px;
    margin-top: -3px;
    padding: 1px 6px;
    background-color: unset;
}

.subsection {
    padding: 1px 8px 10px;
}

.top_bar_flat.item.ghost {
    background-color: var(--col4);
    color: var(--col4);
}

p.top_bar_flat.item.new {
    background-color: var(--item-new);
    cursor: pointer;
}

.top_bar_flat.i-search {
    background-color: var(--input-accent);
    width: calc(100% - 125px);
    vertical-align: top;
    font-size: 21px;
    margin-top: 9px;
    padding: 4px 14px;
}

.type-edit.item.deleted {
    background: var(--col3);
    color: var(--col4);
    text-align: center;
    font-size: 18px;
    width: 100%;
}

.type-edit.item.deleted p {
    padding: 0 0;
    vertical-align: top;
}

span.top-bar-flat.a-right {
    position: absolute;
    right: 5px;
}

.progress.bar {
    background-color: var(--col3);
    width: 0%;
    height: 10px;
}

.progress.cnt {
    background-color: var(--col1);
    width: 100%;
    height: 10px;
}

.NodeEdit.item {
    padding: 0.5rem 5px;
    border-top: var(--border-item) 1px solid;
    /*background-color: var(--col1);*/
    color: var(--text);
    background: var(--background-main);
}
/*.search_page.container.node_editor:last-child{
    padding-bottom:1rem;
}*/
.NodeEdit.scheme.select {
    width: 162px;
}

.NodeEdit.scheme.lbl {
    padding-right: 11px;
}

.NodeEdit.field.lbl {
    width: calc(25% - 10px);
    display: inline-block;
    vertical-align: top;
    text-align: right;
    padding: 1px 9px 0px 0px;
}

.NodeEdit.field.lbl.html {
    width: 100%;
    text-align: left;
    margin-bottom: 9px;
}

.NodeEdit.field.input {
    width: calc(75% - 10vw);
    display: inline-block;
}
@media screen and (max-width: 600px) {
.NodeEdit.field.input {
    width: calc(75% - 5vw);
}
.react-numeric-input input{
    padding:0 !important;
}

}
.nodeEdit.picture.input {
    border-radius: 5px;
    cursor: pointer;
    height: 150px;
    border: 2px var(--shadow-accent-alt) dashed;
    width: 150px;
    overflow: hidden;
}

.nodeEdit.picture.input:hover {
    border: 2px var(--buton-accent) dashed;
}

.nodeEdit.picture.input img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.nodeEdit.picture.input img:hover {
    filter: blur(1px);
}

.nodeEdit.picture.gallery.input {
    display: inline-block;
    width: 47%;
}

.nodeEdit.picture.gallery.input div {
    position: relative;
    width: 100%;
    text-align: center;
    height: 100%;
    font-size: 69px;
    margin-top: 35px;
}

.nodeEdit.gallery {
    /*border: 2px var(--col1) dashed;*/
    padding: 3px;
}

.nodeEdit.gallery.dragging {
    border: 2px var(--col3) dashed;
    padding: 3px;
}

.nodeEdit.file.gallery.input {
    display: inline-block;
    background: var(--file-upload-button);
    border: unset;
    border-radius: 5px;
    margin: 2px 5px;
    padding: 0px 4px;
    min-height: 35px;
    width: 187px;
    text-align: center;
    line-height: 30px;
}

.nodeEdit.picture.input.options {
    position: fixed;
    background: rgba(0, 0, 0, 0.62);
    border: unset;
    border-radius: unset;
    color: white;
    height: 46px;
    bottom: 0;
    left: calc(50% - 75px);
    justify-content: space-between;
}

img.nodeEdit.picture.input.options {
    width: 35px;
    height: 35px;
    position: relative;
    margin-top: unset;
    background: unset;
    left: initial;
    bottom: unset;
    border: initial;
    object-fit: unset;
}


.imgViewer.cnt {
    position: fixed;
    z-index: 999;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.86);
    text-align: center;
}

span.imgViewer.close {
    position: fixed;
    padding: 2px 6px;
    right: 20px;
    border-radius: 4px;
    font-size: 27px;
    color: #fff;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.78);
}

img.imgViewer.target {
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle;
    width: unset !important;
    height: unset !important;
}

img.imgViewer.target:hover {
    filter: unset !important;
}

span.valg-center.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.nodeEdit.picture.gallery.input.options {
    position: absolute;
    left: 0;
    margin-top: 0;
}

.nodeEdit.picture.gallery.input.options.cnt {
    margin: 0px calc(100% - 163px);
}

img.nodeEdit.picture.input.options.cancel {
    display: block;
    position: absolute;
    left: unset;
    right: 0px;
    margin-top: unset;
}

.imgViewer.target.cnt {
    max-width: 90vw !important;
    max-height: 80vh !important;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    top: unset;
    left: unset;
    z-index: unset;
    background: unset;
    position: relative;
    margin-top: 29px;
    text-align: unset;
}

span.imgViewer.prev {
    position: fixed;
    background: rgba(0, 0, 0, 0.78);
    color: #ffffff;
    font-size: 49px;
    top: 50%;
    height: 54px;
    margin-top: -29px;
    z-index: 9;
    left: 4px;
    cursor: pointer;
    display: flex;
    width: 41px;
    justify-content: center;
}

span.imgViewer.next {
    position: fixed;
    background: rgba(0, 0, 0, 0.78);
    color: #ffff;
    font-size: 49px;
    top: 50%;
    height: 54px;
    margin-top: -29px;
    z-index: 9;
    right: 4px;
    cursor: pointer;
    display: flex;
    width: 41px;
    justify-content: center;
}

img.nodeEdit.file.gallery.options {
    border: unset;
    margin-right: 9px;
    padding: 1px 7px;
    filter:var(--file-uploader-icons);
}

div.nodeEdit.file.gallery.extension {
    position: relative;
    border: unset;
    background-color: var(--border-item);
    padding: 3px 6px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 40px;
    display: inline-block;
    left: 7px;
}

.nodeEdit.file.gallery.new.input{
    background: var(--buton-accent);
}

div.nodeEdit.file.gallery.name {
    position: relative;
    border: unset;
    width: 130px;
    padding: 3px 0px;
    display: inline-block;
    left: 0px;
    overflow: hidden;
    font-size: 14px;
    vertical-align: top;
}

.top_bar_flat.item.relation {
    padding: 2px 5px;
    border-top: unset;
    background-color: var(--border-item);
    border-radius: 5px;
}

.CreateEdge.delete_relation {
    position: absolute;
    right: 0;
    padding: 0 11px;
    background: var(--color-grey-15);
    margin: -3px 0px;
    cursor: pointer;
}

img.CreateEdge.delete_relation{
    filter: var(--relations-icons);
}
.CreateEdge.invert_relation {
    position: absolute;
    right: 43px;
    padding: 0 11px;
    background: var(--background-board);
    margin: -3px 0px;
    cursor: pointer;
    border-radius: 5px;
}

.CreateEdge.invert_relation.active{
    background: var(--file-upload-button);
}

.NodeEdit.field.input.section {
    width: 100%;
    margin-bottom: 11px;
}

.type-edit.pos {
    height: 17px;
    text-align: center;
}

.password.input {
    height: 2em;
    margin: 3px 0;
    border-radius: 8px;
    padding: 0 7px;
    font-family: 'Questrial', sans-serif;
    background-color: var(--input-accent);
    color: var(--text);
    border-style: unset;
    width: calc(100% - 22px);
}

.hard-type select {
    width: 115px;
}

p.click-to-edit-text {
    width: calc(97% - 5px);
    font-family: 'Questrial', sans-serif;
    font-size: 20px;
    border-radius: 5px;
    border: unset;
    padding: 5px 5px;
    color: var(--col4);
    display: inline-block;
}

p.click-to-edit-text:hover {
    background-color: var(--col3);
}

p.click-to-edit-text.inactive:hover {
    background-color: var(--col1);
}

div.mcig-cnt {
    width: calc(97% - 5px);
    background-color: var(--background-main);
    border: solid 1px var(--buton-accent);
    margin-top: 6px;
    padding: 5px 5px;
    font-family: 'Questrial', sans-serif;
    font-size: 17px;
}

span.mcig-item {
    display: inline-block;
    background: var(--col2);
    padding: 5px 0;
    text-align: center;
    cursor:pointer;
}

span.mcig-item.active {
    background: var(--buton-accent);
    color: white;
}

.role.select {
    width: calc(100% - 7px);
    padding: 5px;
    margin-top: 7px;
}

p.top_bar_flat.item.title:before {
    content: '';
    width: 97%;
    height: 2px;
    line-height: 0;
    position: absolute;
    border-bottom: 1px solid var(--col4);
    bottom: 14px;
    z-index: 0;
}

.FloatTool.cnt {
    z-index: 20;
    width: 100vw;
    height: 100%;
    background: var(--float-shadow);
}

.smll-pop-up.cnt {
    background-color: var(--background-main);
    position: fixed;
    height: 94px;
    left: 50%;
    width: 300px;
    margin-left: -150px;
    top: 54px;
    border-radius: 5px;
    cursor: default;
    z-index: 99;
    white-space: break-spaces;
}

.boolean-popup.bttngroup {
    position: absolute;
    bottom: 10px;
    right: 10px;
    border: 1px solid var(--border-item);
    padding: 3px;
}

p.boolean-popup.text {
    background-color: unset;
    font-family: 'Questrial', sans-serif;
    border-width: 0;
    font-size: 17px;
    border-radius: 8px;
    color: var(--col4);
    margin-top: 13px;
    padding: 0 17px;
}

.boolean-popup.bttn {
    background-color: unset;
    font-family: 'Questrial', sans-serif;
    border-width: 0;
    font-size: 17px;
    border-radius: 8px;
    color: var(--text);
    display: inline-block;
    padding: 3px 13px;
}

.boolean-popup.ok.bttn:hover {
    background: var(--danger);
}

.boolean-popup.cancel.bttn:hover {
    background: var(--buton-accent);
}

.boolean-popup.select {
    margin-left: 8px;
    margin-top: 10px;
    width: 94%;
}

span.ws_name_bttn {
    display: none;
}

.offline.warning {
    position: fixed;
    z-index: 99;
    background: var(--danger);
    color: var(--col4);
    padding: 8px 9px;
    right: 19px;
    top: 5px;
    border-radius: 5px;
}

.color.picker.cnt {
    margin: 0 3px;
    display: inline;
}

.color.picker.bigpreview {
    height: 58px;
    width: 97%;
    margin: 0 auto;
    margin-top: 3px;
    margin-bottom: 4px;
}

.color.picker.lbl {
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid var(--text)
}
.color.picker.lbl option{
    color: #53535f;
}

option{
    color: #53535f;
}

.react-numeric-input{
    color:var(--text);
}

.tag.new.bttn.wrp {
    bottom: 10px;
    position: absolute;
    width: 36%;
    text-align: center;
    border: 1px solid var(--buton-accent);
    padding: 3px;
    left: 12px;
}

.tag.delete.bttn.wrp{
    bottom: 10px;
    position: absolute;
    width: 36%;
    text-align: center;
    border: 1px solid var(--danger);
    padding: 3px;
    left: 12px;

}

input.tag.text.input {
    width: 90%;
    margin: 6px 7px;
    padding: 8px 8px;
    color: var(--text);
    background: var(--search-bar);
    border-width: 0;
    border-radius: 5px;
    font-size: 14px;
}

.tag.edit.wrp {
    /*width: fit-content;
    margin: 0 auto;*/
    margin-top: 3px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.tag.item {
    padding: 5px 7px;
    color: #eeeeee;
    font-size: 17px;
    border-radius: 3px;
    margin: 3px 0;
    position: relative;
    width: 93%;
}

.tag.item p {
    margin-left: 9px;
    display: inline
}

.tag.item div {
    position: absolute;
    right: 6px;
    display: inline-block;
}

.tag.index {
    margin: 0 auto;
    width: 90%;
    height: 134px;
    overflow-y: auto;
}

.tmp.notification.wrp {
    position: fixed;
    color: var(--text);
    bottom: 82px;
    width: 100%;
    z-index: 9999;
}

.tmp.notification.item {
    background: var(--background-main);
    width: 275px;
    margin: 0 auto;
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
    padding: 8px 15px;
    margin-bottom: 2px;
    text-align: center;

}

.three.point.btn:after {
    content: '\2807';
    display: block;
    margin: 0 auto;
    width: 9px;
    /*margin-top: -1px;*/
    margin: 3px auto 0;
}

.three.point.btn:hover {
    background: var(--col2);
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
}

.three.point.btn p {
    font-size: 16px;
    padding: 9px;
    cursor: pointer;
}

.node.scheme.prev {
    width: 227px;
    background: var(--col1);
    position: relative;
    margin: 0 auto;
    padding: 3px 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 13px;
}

.navigation.bar {
    position: absolute;
    background: var(--background-main);
    font-family: 'Questrial', sans-serif;
    top: 0px;
    right: 0px;
}

.navigation.bar.collapse {
    cursor: pointer;
}

.navigation.bar.collapse {
    top: 30px
}

.navigation.bar .collapse.bnt.cnt {
    display: inline-block;
    height: 30px;
    width: 30px;
    overflow: hidden;
    vertical-align: middle;
    position: absolute;
    right: 0;
    background: var(--background-main);
}



.navigation.bar .title {
    color: var(--indigo1);
    width: 100vw;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
}

.navigation.bar .list {
    height: calc(100vh - 114px);
    overflow-y: auto;
}

.navigation.bar .filter {
    position: relative;
    padding-left: 30px;
}

.navigation.bar .filter p {
    color: var(--col4);
    font-size: 12px;
    padding-bottom: 2px;
}

.navigation.bar .filter input {
    font-size: 17px;
    height: 23px;
    padding-left: 12px;
    width: calc(100% - 60px);
    margin-bottom: 6px;
    background: var(--search-bar);
    border-style:none;
    border-radius:3px;
    color:var(--text);
}

.navigation.bar .list .cnt .node {
    color: var(--col4);
    margin-bottom: 3px;
    height: 57px;
    position: relative;
    padding-left: 84px;
}

.navigation.bar .list .cnt .node:hover {
    background: var(--color-grey-14);
}

.navigation.bar .list .cnt .node .name {
    padding-top: 9px;
    font-size: 17px;
}

.navigation.bar .list .cnt .node .scheme {
    font-size: 14px;
}

.navigation.bar .list .cnt {
    margin-top: 3px;
}

.navigation.bar .list .cnt .select.indicator {
    height: 40px;
    width: 40px;
    border-radius: 42px;
    position: absolute;
    top: 7px;
    left: 36px;
}

.navigation.bar .list .cnt .select.indicator.active {
    border: 2px solid var(--blue1);
}

.navigation.bar .list .cnt .cover {
    width: 36px;
    background: var(--colrgba);
    height: 36px;
    position: absolute;
    top: 11px;
    left: 40px;
    border-radius: 50px;
    overflow: hidden;
}

.navigation.bar .list .cnt .cover img {
    object-fit: cover;
    width: inherit;
    height: inherit;
}

.navigation.bar .list .cnt .espere {
    width: fit-content;
    margin: 0 auto;
    color: var(--col4);
    font-size: 13px;
}

.navigation.bar .list .recommendation.title {
    text-align: unset;
    position: relative;
    padding-left: 8px;
    font-size: 13px;
    color: var(--col4);
}

.navigation.bar .list .recommendation.item {
    color: var(--col4);
    font-size: 13px;
    padding-left: 63px;
    cursor: pointer;
    margin: 4px 0;
    position: relative;
}

.navigation.bar .list .see.more {
    color: var(--text);
    font-size: 13px;
    text-align: center;
    border: 1px solid var(--buton-accent);
    padding: 6px 0;
    cursor: pointer;
    width: 90%;
    position: relative;
    margin: 0 auto;
    border-radius:5px;
    transition: 300ms linear;
}

.navigation.bar .list .see.more:hover {
    color: white;
    background:var(--buton-accent);
    /*border: 1px dashed var(--new-board);*/
    /*box-shadow:0px 2px 2px 0px var(--button-background-bar) , 0px 3px 1px -2px var(--button-background-bar) , 0px 1px 5px 0px var(--button-background-bar);*/
}

.navigation.bar .list .recommendation.title span {
    position: absolute;
    right: 28px;
    top: 0;
}

.navigation.bar .list .recommendation.item p {
    width: 161px;
    display: inline-block;
    overflow: hidden;
}

.recommendation.title select {
    width: 52%;
    position: absolute;
    top: 1px;
    right: 26px;
    font-size: 12px;
}

.navigation.bar .cnt .recommendation.item .selected.indicator {
    background: var(--blue1);
    width: 10px;
    height: 10px;
    position: absolute;
    left: 45px;
    top: 3px;
    border-radius: 5px;
}

.navigation.bar .filter span {
    color: var(--col4);
    font-size: 12px;
    padding-bottom: 2px;
    position: absolute;
    top: 1px;
    right: 17px;
}

.navigation.bar .spacer {
    height: 18vh;
}

.quick.create.cnt {
    position: fixed;
    width: 100vw;
    height: 100%;
    min-height: 396px;
    z-index: 199;
}

.quick.create.child {
    position: fixed;
    width: 348px;
    background: var(--background-main);
    height: 129px;
    top: 54px;
    border-radius: 5px;
    left: 50%;
    margin-left: -174px;
}

.quick.create.select.scheme {
    position: relative;
    width: 94%;
    margin: 0 7px;
}

input.quick.create.name {
    width: 90%;
    margin: 6px 7px;
    padding: 8px 8px;
    color: var(--text);
    background: var(--search-bar);
    border-width: 0;
    border-radius: 5px;
    font-size: 14px;
}

/* Desk fix */
@media screen and (min-width: 600px) {
    .Main {
        display: grid;
        grid-template-columns: 60px auto;
    }

    .appfloatcnt {
        width: fit-content;
        margin: 0 auto;
    }

    .FloatTool.cnt {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 20;
    }

    .TopBar {
        position: relative;
        margin: 0 auto;
        width: 600px;
        background-color: var(--background-main);
        border-radius:0 0 5px 5px;
    }

    .Editor {
        position: fixed;
        width: 100vw;
        height: 100%;
        margin: 0px 0px 0px 0px;
        min-height: 396px;
    }

    .NavigationBar {
        width: 60px;
        height: calc(100vh - 46px);
        margin: -46px 0px 0px;
        padding: 46px 0px 0px;
        min-height: 350px;
    }

    .nav-wrapper {
        width: 50px;
        margin: 0 auto;
    }

    .NavBttn {
        width: 40px;
        height: 40px;
        margin: 5px 3px 40px 5px;
        display: block;
    }

    .NavLabel {
        position: absolute;
        width: 50px;
        margin: 3px 0px 0px -5px;
        text-align: center;
        font-size: 14px;
    }

    .node.previewcnt {
        margin-top: calc(-100vh + 80px);
    }

    p.node.textcomplete.pinned {
        display: block;
    }

    span.node.pin {
        display: block;
        background-color: var(--col4);
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 5px;
        margin-left: 244px;
    }

    .node.previewcnt.pinned {
        width: 260px;
        right: 18px;
    }

    .node.textpreview.pinned {
        display: none;
    }

    .search_page.container {
        position: relative;
        padding-top: 0px;
    }

    .navigation.bar {
        max-width: 300px;
        top: 54px;
        right: 0;
    }

    .navigation.bar .title {
        width: 300px;
    }

    .navigation.bar.collapse {
        top: 54px;
    }

    .navigation.bar .list .cnt {
        padding-bottom: 47px;
    }

    .navigation.bar.maximized {
        right: unset;
        width: 540px;
        max-width: unset;
        margin-left: calc(50vw - 540px + 270px);
        top: 44px;
    }

    .navigation.bar.maximized .recommendation-list {
        margin: 0 56px 0 19px;
    }
}

@media screen and (min-width: 1000px) {
    .TopBar.main {
        position: relative;
        margin: 0 auto;
        width: 1065px;
        background-color: var(--background-main);
        border-radius: 0 0 5px 5px;
    }

    .WsBttn.main {
        width: 40px;
        height: 40px;
        margin: 3px;
        /*display: inline-flex;*/
    }

    span.ws_name_bttn {
        display: inline-block;
        /*position: absolute;*/
        color: var(--text);
       /* padding: 7px 0;*/
        font-size: 20px;
        /*left: 56px;*/
        max-width: 345px;
        overflow: hidden;
        max-height: 19px;
/*        top: 4px;*/
        padding-bottom: 12px;
    }

.navigation.bar .title {
    width: 100%;
    min-width: 300px;
}

.navigation.bar.maximized {
    right: unset;
    width: 800px;
    max-width: unset;
    margin-left: calc(50vw - 800px + 400px);
    top: 44px;
}

    .navigation.bar.maximized .recommendation-list {
        margin: 0 150px 0 43px;
    }

    .navigation.bar.maximized.public {
        width: 540px;
        margin-left: calc(50vw - 540px + 270px);
    }
}
html body div#root div.App div div.FloatTool.cnt div.app.float div.appfloatcnt form#edit-form div.search_page.container div div.NodeEdit.item span.NodeEdit.field.input div div.tox.tox-tinymce div.tox-editor-container div.tox-editor-header div.tox-toolbar-overlord svg{
    filter: none;
}
