.no-more-nodes-popup > button {
    padding: 15px 20px;
    background: var(--buton-accent);
    font-weight: 500;
    color: white;
    border-radius: 10px;
    margin: 26px 0 0 0;
    font-size: 16px;
}

.no-more-nodes-popup > h1 {
    font-weight: 500;
    font-size: 20px;
}

.no-more-nodes-popup > p {
    margin: 26px 0 0 0;
    font-size: 16px;
}