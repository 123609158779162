$react-selects: 20;

textarea:focus,
input:focus {
  outline: 1px solid transparent;
  transition: 180ms box-shadow ease-in-out;
  box-shadow: 0 0 2px 1px #90CDF4;
  box-sizing: border-box;
  border: 1px solid #63B3ED;
}

textarea:focus{
  padding:0.5rem;
}

input,
input::before,
input::after {
  box-sizing: border-box;
  transition: 180ms box-shadow ease-in-out;
}

@for $i from 1 through $react-selects{
  #react-select-#{$i}-input:focus{
    outline:none;
    border:none;
    box-shadow:none;
  } 
}
