.ws-item {
  background: var(--background-main);
  display: flex;
  padding: 6px 0;
  justify-content: space-between;
  width: 90%;
  margin: 5px auto;
  border-radius: 5px;
  .cover {
    width: 150px;
    height: 78px;
    overflow: hidden;
    border-radius: 5px;
    margin: 0 10px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .metadata {
    width: inherit;
    margin: 0px 6px;
    .context {
      min-height: 52px;
      margin-bottom: 4px;
      h2 {
        font-size: 17px;
      }
      p {
        font-size: 14px;
      }
    }
    .toolbar {
      display: flex;
      justify-content: flex-end;
      position: relative;
      .smll-pop-up.cnt {
        left: calc(100% - 192px) !important;
        top: 30px !important;
        font-size: 14px !important;
        width: 180px !important;
      }
      button {
        background: var(--btn-regular-bg-hov);
        height: 26px;
        margin: 0 3px;
      }
      button.main {
        padding: 0 10px;
        font-size: 14px;
      }
      .float {
        button {
          padding: 5px 0;
        }
      }
    }
  }
}

.view_more {
  width: 100%;
  text-align: center;
  padding: 20px 0px;
  .cnt {
    width: fit-content;
    display: flex;
    align-items: center;
    button {
      background: var(--btn-regular-bg);
      padding: 18px 13px;
      margin: 0 10px;
    }
    .new-ws {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      padding: 10px 13px;
      background: var(--buton-accent);
      .loader {
        width: 100%;
        position: absolute;
        bottom: 0;
      }
    }
  }
}

@media screen and (min-width: 550px) {
  .ws-item.shadow {
    .cover {
      width: 210px;
      height: 109px;
    }
    .metadata {
      position: relative;
      .toolbar {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        bottom: 2px;
        right: 0;
        button {
          background: var(--btn-regular-bg-hov);
          height: 39px;
          margin: 0 3px;
          padding: 0 7px;
        }
        .smll-pop-up.cnt {
          left: -120px !important;
          top: 42px !important;
          font-size: 15px !important;
          width: 200px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 800px) {
  .ws-list {
    display: grid;
    grid-template-columns: 50% 50%;
    .ws-item.shadow {
      .cover {
        width: 210px;
        height: 109px;
      }
    }
  }
}
