.item {
  .getLink {
    position: relative;
    z-index: 99;
    background: rgba(55, 55, 55, 0.18);
    backdrop-filter: blur(9px);
    margin: 14px 11px;
    padding: 3px 4px;
    border-radius: 6px;
    box-shadow: 0px 0px 3px 0px var(--background-main);
    display: flex;
    height: 29px;
    align-items: center;
    p {
      color: white
    }
    svg {
        filter: invert(1) !important;
    }
  }
}
